<template>
  <moe-page title="供应商列表">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <div class="font-20 fwb">供应商管理列表</div>
      </template>

      <!-- 筛选信息 -->
      <moe-inquire @search="supplierSearch">
        <el-form-item label="供应商编号">
          <el-input :value="supplierParams.id" @input="(value) => supplierParams.id = value.replace(/^\.+|[^\d]/g, '')" placeholder="输入供应商编号" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="供应商名称">
          <el-input v-model.trim="supplierParams.name" placeholder="输入供应商名称" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="状态">
          <moe-select type="statusList" v-model="supplierParams.status" placeholder="选择状态"></moe-select>
        </el-form-item>
      </moe-inquire>

      <moe-table ref="supplierTableRef" url="/shop/vendor/list" :params="supplierParams" :number-show="false">
        <el-button slot="tool" type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/supplierManage/add')">新增供应商</el-button>

        <el-table-column label="供应商编号" prop="id" width="100" />
        <el-table-column label="供应商名称" prop="name" min-width="130" :show-overflow-tooltip="true" />
        <el-table-column label="供应商介绍" prop="introduce" min-width="130" :show-overflow-tooltip="true" />
        <el-table-column label="状态" min-width="150">
          <template slot-scope="{ row }">
            <moe-switch @change="(e) => req_updateVendorStatus(row, e)" :activeValue="true" :inactiveValue="false" v-model="row.status" activeText="启用" inactiveText="禁用"></moe-switch>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="200" fixed="right">
          <template slot-scope="{ row, $index }">
            <el-button type="primary" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/supplierManage/add`, { id: row.id })">编辑</el-button>
            <el-button icon="el-icon-delete" size="small" type="danger" @click="req_delVendor(row, $index)">删除</el-button>
          </template>
        </el-table-column>
      </moe-table>
    </moe-card>
  </moe-page>
</template>

<script>
export default {
  name: 'SupplierManageList',
  data() {
    return {
      supplierParams: {
        pageNum: 1,
        pageSize: 10,
        status: '',
        name: '',
        id: '',
      }
    }
  },
  methods: {
    supplierSearch(isSearch) {
      if (!isSearch) {
        this.supplierParams = {
          pageNum: 1,
          pageSize: 10,
          status: '',
          name: '',
          id: '',
        }
      }

      this.$refs['supplierTableRef'].searchData();
    },
    /** 更新供应商状态 */
    req_updateVendorStatus({ id }, status) {
      this.$moe_api.SUPPLIER_API.updateVendorStatus({ id, status }).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('编辑成功', {
            completionHandler: () => {
              this.supplierSearch(true);
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },
    /** 删除供应商 */
    req_delVendor({ id, name }) {
      this.$moe_layer.confirm(`您确定要删除当前选项"${name}"吗？`, () => {
        this.$moe_api.SUPPLIER_API.delVendor({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('删除成功', {
              completionHandler: () => {
                this.$refs['supplierTableRef'].searchData();
              }
            });
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      });
    },
  }
}
</script>

<style lang="scss">

</style>